<template>
  <v-menu
    v-model="_open"
    :close-on-content-click="false"
    :nudge-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :ripple="false" class="ml-2" small>
        {{ filterIcon }}
      </v-icon>
    </template>
    <v-card>
      <v-list>
        <v-list-item
          class="clickable"
          :class="{ 'font-weight-bold': isSortAsc }"
          @click="onManualSort()"
        >
          <v-list-item-avatar>
            <v-icon> mdi-sort-alphabetical-ascending </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> Ordenar ascendente </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="clickable"
          :class="{ 'font-weight-bold': isSortDesc }"
          @click="onManualSort(true)"
        >
          <v-list-item-avatar>
            <v-icon> mdi-sort-alphabetical-descending </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              :class="{ 'font-weight-bold': isSortDesc }"
            >
              Ordenar descendente
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-autocomplete
              v-if="field.autocomplete"
              v-model="_value"
              class="search-field"
              deletable-chips
              dense
              hide-details
              :items="field.items"
              :item-value="field.itemValue"
              :item-text="field.itemText"
              multiple
              outlined
              persistent-placeholder
              placeholder="Busque por termos"
              ref="searchField"
              small-chips
            ></v-autocomplete>
            <input-chip
              v-else
              v-model="_value"
              append-icon="mdi-magnify"
              class="search-field"
              hide-details
              placeholder="Separe termos por ; ou Enter"
              ref="searchField"
              @keyup="onKeyUp"
            ></input-chip>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <div class="d-flex justify-content-around w-100">
            <v-btn
              class="btn-clear"
              color="primary"
              text
              @click="onClear"
            >
              Limpar
            </v-btn>
            <v-btn
              class="btn-filter"
              color="primary"
              dark
              dense
              depressed
              @click="onFilter"
            >
              Filtrar
            </v-btn>
            <v-spacer />
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  components: {
    "input-chip": () => import("@/components/input-chip.vue"),
  },
  computed: {
    _open: {
      get: function () {
        return this.open;
      },
      set: function (value) {
        this.$emit('update:open', !!value);
      },
    },
    _value: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit('update:value', value);
        this.$emit('input', value);
      },
    },
    filterIcon: function () {
      return this.value?.length > 0
        ? "mdi-filter"
        : "mdi-filter-outline";
    },
    isSortAsc: function () {
      return this.sortDirection === 'asc';
    },
    isSortDesc: function () {
      return this.sortDirection === 'desc';
    },
  },
  methods: {
    focus: function () {
      this.$refs.searchField?.focus();
    },
    onClear: function () {
      this._value = [];
      this.$emit('clear');
      this._open = false;
    },
    onFilter: function () {
      setTimeout(() => {
        this.$emit('filter', this._value);
        this._open = false;
      }, 100);
    },
    onKeyUp: function (event) {
      const { code, ctrlKey } = event;

      if (ctrlKey && ['Enter', 'NumpadEnter'].includes(code)) {
        const onFilter = this.onFilter;
        setTimeout(onFilter, 100);
      }

      this.$emit('keyup', event);
    },
    onManualSort: function (desc = false) {
      this.$emit('manualSort', desc);
      this._open = false;
    },
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    open: Boolean,
    sortDirection: String,
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  watch: {
    open: function (value) {
      if (value) {
        const focus = this.focus;
        setTimeout(focus, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item.clickable {
  cursor: pointer;
}

.search-field {
  min-width: 265px;

  &.v-autocomplete {
    min-width: 235px;
  }
}

.v-btn {
  &.btn-clear,
  &.btn-filter {
    min-width: 130px;
  }

  &.btn-filter {
    margin-left: 5px; // Fecha os 265px do search-field
  }
}
</style>
